import { useEffect, useState } from 'react';

function useIsIphone() {
    const [isIphone, setIsIphone] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isMSStream = typeof window.MSStream !== 'undefined';
        setIsIphone(/iPhone/.test(userAgent) && !isMSStream);
    }, []);

    return isIphone;
}

export default useIsIphone;
