import {FaDiscord, FaYoutube} from 'react-icons/fa';
import './Footer.css';
import {FaXTwitter} from "react-icons/fa6";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-content">
                <span>© 2024 Samurai Redemption NFT</span>
                <div className="social-icons">
                    <a href="https://twitter.com/SamuraiRed2088" target="_blank" rel="noopener noreferrer"><FaXTwitter
                        style={{width: '1.5vw', height: '1.5vh'}}/></a>
                    <a href="https://discord.gg/samurairedemption" target="_blank" rel="noopener noreferrer"><FaDiscord
                        style={{width: '1.5vw', height: '1.5vh'}}/></a>
                    <a href="https://www.youtube.com/@SamuraiRedemption" target="_blank"
                       rel="noopener noreferrer"><FaYoutube
                        style={{width: '1.5vw', height: '1.5vh'}}/></a>
                </div>
            </div>
        </div>
    );
};

export default Footer;