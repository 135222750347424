import React, {useState} from 'react';
import "./CoreTeamCard.css"

function CoreTeamCard({imageUrl, name, tag, bio}) {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleCardClick = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <div className={`coreteam-card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick}>
            <div className="card-front">
                <img src={imageUrl} alt="NFT"/>
                <div className="coreteam-card-info">
                    <p className="name highlight">{name}</p>
                    <p className="tag">{tag}</p>
                </div>
            </div>
            <div className="card-back">
                <div className="coreteam-card-info-back">
                    <div className='card-back-name-and-tag'>
                        <p className="highlight">{name}</p>
                        <p className="tag">{tag}</p>
                    </div>
                    <div className='card-back-bio'>
                        <p className="bio">
                            {bio}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoreTeamCard;
