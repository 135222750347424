import React, {useState} from 'react';
import './Tokenomics.css';
import useVisibleOnScreen from "../../hooks/useVisibleOnScreen";
import TokenomicsChart from "./TokenomicsChart";
import pieChartImage from "../../assets/tokenomics/pie-chart.webp";
import {HeaderInfoIcon} from "../icons/HeaderInfoIcon";
import {Dialog} from "../popup/dialog";
import {UtilityIcon} from "../icons/UtilityIcon";
import {FireIcon} from "../icons/FireIcon";

const Tokenomics = React.forwardRef(({setActiveLink}, ref) => {
    const isVisible = useVisibleOnScreen(ref);
    const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);

    const openInfoDialog = () => setInfoDialogOpen(true);
    const closeInfoDialog = () => setInfoDialogOpen(false);

    const infoDialogText = (
        <p>The Samurai Redemption Game introduces its exclusive token, $<span
            className="highlight">MON</span> with a capped supply of 1 billion units,<br></br>
            fostering a rare and valuable ecosystem. Meticulously planned token distribution aims to ensure
            stability in the<br></br> token price, contributing to a sustainable and thriving in-game economy.
        </p>
    );
    return (
        <div ref={ref} className={`tokenomics-container ${isVisible ? 'animate' : ''}`}>
            <div className="headline">
                <h1 className="tokenomics-title"><span className="highlight">TOKEN</span>OMICS
                    <span className='tokenomics-info-icon' onClick={openInfoDialog}>
                    <HeaderInfoIcon width='25' height='25'/>
                </span>
                </h1>

                <p className='headline-paragraph'>The Samurai Redemption Game introduces its exclusive token, $<span
                    className="highlight">MON</span> with a capped supply of 1 billion units,<br></br>
                    fostering a rare and valuable ecosystem. Meticulously planned token distribution aims to ensure
                    stability in the<br></br> token price, contributing to a sustainable and thriving in-game economy.
                </p>
            </div>
            {isInfoDialogOpen && (
                <Dialog
                    type="info"
                    title="Tokenomics"
                    text={infoDialogText}
                    onClose={closeInfoDialog}
                />
            )}
            <div className="tokenomics-chart hidden-on-web">
                <img src={pieChartImage} alt="Pie Chart"/>
            </div>
            <div className='tokenomics-statistics hidden-on-web'>
                <div className='statistics-items-wrapper'>
                    <div className="statistics-item">
                        <div className="circle-red"></div>
                        <div className="text-container"> {/* New container for text */}
                            <h1>GAME Yield (85%)</h1>
                            <p>85% of the total token supply will be earned by NFT<br></br> holders who successfully
                                complete in-game missions</p>
                        </div>
                    </div>
                    <div className="statistics-item">
                        <div className="circle-blue"></div>
                        <div className="text-container"> {/* New container for text */}
                            <h1>MARKETING (10%)</h1>
                            <p>10% is reserved specifically for marketing initiatives,<br></br> aimed at boosting the
                                game's popularity and reach</p>
                        </div>
                    </div>
                    <div className="statistics-item">
                        <div className="circle-orange"></div>
                        <div className="text-container"> {/* New container for text */}
                            <h1>LIQUIDITY (5%)</h1>
                            <p>5% in reserved for liquidity pool</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='divider'>
                <div className='tokenomics-horizontal-line'></div>
            </div>
            <div className='utilities hidden-on-web'>
                <div className='utility'>
                    <div className='utility-icon'>
                        <UtilityIcon/>
                    </div>
                    <div className='utility-text'>
                        <p className='utility-header'>Utility:</p>
                        <p className='utility-description'>In game acquisitions</p>
                    </div>
                </div>
                <div className='utility'>
                    <div className='utility-icon'>
                        <FireIcon/>
                    </div>
                    <div className='utility-text'>
                        <p className='utility-header'>Burn</p>
                        <p className='utility-description'>95% of User-Spent Tokens Burned Monthly</p>
                    </div>
                </div>
            </div>
            <TokenomicsChart/>
        </div>
    );
});

export default Tokenomics;
