import React from 'react';
import './RmCard.css';
import {CheckIcon} from "../icons/CheckIcon";
import {CircleIcon} from "../icons/CircleIcon";

const RmCard = ({args}) => {
    return (
        <div className="rm-card">
            {args.map((arg, index) => (
                <div key={index} className="rm-card-row">
                    <p className="rm-card-icon">{arg.icon === "circle" ? <CircleIcon width='20' height='20'/> :
                        <CheckIcon width='20' height='20'/>}</p>
                    <p className="rm-card-text">{arg.text}</p>
                </div>
            ))}
        </div>
    );
};
export default RmCard;