import React from 'react';
import './NftBox.css'; // Import the CSS for styling

const NftBox = ({ title, text }) => {
    return (
        <div className="nft-box">
            <h2>{title}</h2>
            <p>{text}</p>
        </div>
    );
}

export default NftBox;