import React, {useEffect, useRef} from 'react';
import './dialog.css';
import {InfoIcon} from "../icons/InfoIcon";
import {ErrorIcon} from "../icons/ErrorIcon";
import {SuccessIcon} from "../icons/SuccessIcon";

export const Dialog = ({type = 'info', title, text, onClose}) => {
    const backdropRef = useRef(null);

    const getIcon = (type) => {
        const icons = {
            info: <InfoIcon width='40' height='40'/>,
            error: <ErrorIcon width='40' height='40'/>,
            success: <SuccessIcon width='40' height='40'/>,
        };
        return icons[type] || icons.info;
    }

    // disable vertical scroll when dialog is open
    const preventPropagation = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        const backdropElement = backdropRef.current;

        if (backdropElement) {
            backdropElement.addEventListener('touchmove', preventPropagation, {passive: false});

            return () => {
                backdropElement.removeEventListener('touchmove', preventPropagation);
            };
        }
    }, []);

    return (
        <div ref={backdropRef} className="dialog-backdrop">
            <div className='dialog-container show'>
                <div className='dialog-content-wrapper'>
                    <div className='dialog-icon'>
                        {getIcon(type)}
                    </div>
                    <div className="dialog-content">
                        <h2 className="dialog-title">{title}</h2>
                        <div className="dialog-text">{text}</div>
                    </div>
                    <button className="no-select dialog-close-btn" onClick={onClose}>Okay</button>
                </div>
            </div>
        </div>

    );
};
