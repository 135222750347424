import React from 'react';
import './YoutubePlayer.css';
import useVisibleOnScreen from "../../hooks/useVisibleOnScreen";

const YouTubePlayer = React.forwardRef(({setActiveLink}, ref) => {
    const videoUrl = 'https://www.youtube.com/embed/c2RzI0WUst8';
    const isVisible = useVisibleOnScreen(ref);
    return (
        <div ref={ref} className={`youtube-page ${isVisible ? 'animate' : ''}`}>
            <div className="content-container">
                <h1 className="video-title">
                    Samurai <span className="highlight">Redemption</span>
                </h1>
                <iframe
                    src={videoUrl}
                    title="Teaser"
                    allowFullScreen
                    className="youtube-iframe"
                    // srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/c2RzI0WUst8/?autoplay=1><img src=https://www.youtube.com/embed/c2RzI0WUst8/hqdefault.jpg alt='Game trailer'><span>▶</span></a>`}
                ></iframe>
            </div>
        </div>
    );
});

export default YouTubePlayer;
