import React from 'react';

export const SuccessIcon = ({width = "50", height = "50", fill = "none"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 50 50" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect x="14" y="15" width="21" height="20" fill="white"/>
        <path
            d="M38.1931 4.16699C42.2764 4.16699 45.6098 7.36908 45.8223 11.4003L45.8327 11.8066V38.1941C45.8327 42.2774 42.6306 45.6107 38.5993 45.8232L38.1931 45.8337H11.8056C9.84972 45.8338 7.96829 45.0837 6.54894 43.738C5.12959 42.3923 4.28044 40.5534 4.17643 38.6003L4.16602 38.1941V11.8066C4.16602 7.72324 7.3681 4.38991 11.3993 4.17741L11.8056 4.16699H38.1931ZM32.7223 19.3607C32.3316 18.9702 31.8018 18.7508 31.2493 18.7508C30.6969 18.7508 30.1671 18.9702 29.7764 19.3607L22.916 26.2191L20.2223 23.5274L20.0264 23.3545C19.6077 23.0307 19.0814 22.8785 18.5545 22.9287C18.0276 22.9789 17.5395 23.2277 17.1894 23.6247C16.8394 24.0218 16.6536 24.5371 16.6697 25.0662C16.6859 25.5953 16.9028 26.0983 17.2764 26.4732L21.4431 30.6399L21.6389 30.8128C22.0398 31.1238 22.5403 31.2778 23.0466 31.2459C23.5529 31.2141 24.0302 30.9986 24.3889 30.6399L32.7223 22.3066L32.8952 22.1107C33.2061 21.7099 33.3601 21.2094 33.3283 20.7031C33.2964 20.1968 33.081 19.7195 32.7223 19.3607Z"
            fill="#4BB543"/>
    </svg>
);