import React from 'react';
import pieChartImage from '../../assets/tokenomics/pie-chart.webp';
import {UtilityIcon} from "../icons/UtilityIcon";
import {FireIcon} from "../icons/FireIcon";

const TokenomicsChart = () => {

    return (
        <div className="tokenomics-chart-container">
            <img src={pieChartImage} alt="Pie Chart" className="pie-chart"/>
            <div className="chart-data">
                <div className="data-item">
                    <div className="circle-red"></div>
                    <div className="text-container"> {/* New container for text */}
                        <h1>GAME Yield (85%)</h1>
                        <p>85% of the total token supply will be earned by NFT<br></br> holders who successfully
                            complete in-game missions</p>
                    </div>
                </div>
                <div className="data-item">
                    <div className="circle-blue"></div>
                    <div className="text-container"> {/* New container for text */}
                        <h1>MARKETING (10%)</h1>
                        <p>10% is reserved specifically for marketing initiatives,<br></br> aimed at boosting the game's
                            popularity and reach</p>
                    </div>
                </div>
                <div className="data-item">
                    <div className="circle-orange"></div>
                    <div className="text-container"> {/* New container for text */}
                        <h1>LIQUIDITY POOL (5%)</h1>
                        <p>5% in reserved for liquidity pool</p>
                    </div>
                </div>

                <div className="horizontal-line"></div>

                <div className="data-item">
                    <UtilityIcon pattern="pattern0_5"/>
                    <div className="text-container">
                        <h1>Utility</h1>
                        <p>in game acquisitions</p>
                    </div>
                </div>
                <div className="data-item">
                    <FireIcon pattern="pattern0_2"/>
                    <div className="text-container">
                        <h1>Burn</h1>
                        <p>80% of User-Spent Tokens Burned Monthly</p>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default TokenomicsChart;
