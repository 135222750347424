import React, {useEffect, useRef} from 'react';
import './Header.css';
import logo from '../../assets/header/logo-100x100.webp';
import {FaBars, FaDiscord, FaTimes, FaYoutube} from 'react-icons/fa';
import {FaXTwitter} from "react-icons/fa6";
import {useLocation} from "react-router-dom";

function Header({
                    activeLink,
                    setActiveLink,
                    dashboardRef,
                    youtubePlayerRef,
                    nftRef,
                    roadMapRef,
                    tokenomicsRef,
                    airdropRef,
                    coreteamRef,
                    setCurrentSectionIndex,
                    setActiveSection,
                    isBurgerMenuOpen,
                    setIsBurgerMenuOpen
                }) {
    const headerClass = `header ${activeLink !== 'dashboard' ? 'non-dashboard' : ''}`;
    const burgerMenuRef = useRef(null);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const sectionName = hash.replace('#', '');
            handleSetActiveLink(sectionName);
        }
    }, []);

    const toggleBurgerMenu = () => {
        setIsBurgerMenuOpen(!isBurgerMenuOpen);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (burgerMenuRef.current && !burgerMenuRef.current.contains(event.target)) {
                setIsBurgerMenuOpen(false);
            }
        }

        if (isBurgerMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isBurgerMenuOpen]);

    const handleWhitePaperClick = () => {
        window.open('https://docs.samuraired.io/', '_blank');
    };

    const handleSetActiveLink = (linkName) => {
        switch (linkName) {
            case 'dashboard':
                navigateToSection(dashboardRef, 0, 'dashboard');
                break;
            case 'trailer':
                navigateToSection(youtubePlayerRef, 1, 'trailer');
                break;
            case 'nft':
                navigateToSection(nftRef, 2, 'nft');
                break;
            case 'roadmap':
                navigateToSection(roadMapRef, 3, 'roadmap');
                break;
            case 'tokenomics':
                navigateToSection(tokenomicsRef, 4, 'tokenomics');
                break;
            case 'airdrop':
                navigateToSection(airdropRef, 5, 'airdrop');
                break;
            case 'coreteam':
                navigateToSection(coreteamRef, 6, 'coreteam');
                break;
            default:
                navigateToSection(dashboardRef, 0, 'dashboard');
                break;
        }
        setIsBurgerMenuOpen(false);
    };

    function navigateToSection(ref, index, section) {
        setActiveLink(section);
        if (ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
        setCurrentSectionIndex(index);
        setActiveSection(section);

        if (isBurgerMenuOpen) {
            setIsBurgerMenuOpen(false);
        }
    }

    return (
        <header className={headerClass}>

            <div className="no-select burger-icon" onClick={toggleBurgerMenu}>
                {isBurgerMenuOpen ? <FaTimes className={`icon ${isBurgerMenuOpen ? 'fadeIn' : 'fadeOut'}`}
                                             style={{width: '100%', height: '100%', color: '#FFF'}}/> :
                    <FaBars className={`icon ${isBurgerMenuOpen ? 'fadeOut' : 'fadeIn'}`}
                            style={{width: '100%', height: '100%', color: '#FFF'}}/>}
            </div>

            <nav ref={burgerMenuRef} className={`burger-nav ${isBurgerMenuOpen ? 'burger-nav-open' : ''}`}>
                <div className="nav-logo-container">
                    <a href="#dashboard" onClick={() => navigateToSection(dashboardRef, 0, 'dashboard')}
                       className="logo-link no-select">
                        <img src={logo} className="logo" alt="logo"/>
                    </a>
                </div>
                <ul className='nav-items'>
                    <li className='nav-item'>
                        <a href="#dashboard"
                           className={`nav-item-link no-select ${activeLink === 'dashboard' ? 'active' : ''}`}
                           onClick={() => handleSetActiveLink('dashboard')}>HOME</a>
                    </li>
                    <li className='nav-item'>
                        <a href="#trailer"
                           className={`nav-item-link no-select ${activeLink === 'trailer' ? 'active' : ''}`}
                           onClick={() => handleSetActiveLink('trailer')}>GAME</a>
                    </li>
                    <li className='nav-item'>
                        <a href="#nft"
                           className={`nav-item-link no-select ${activeLink === 'nft' ? 'active' : ''}`}
                           onClick={() => handleSetActiveLink('nft')}>NFT</a>
                    </li>
                    <li className='nav-item'>
                        <a href="#roadmap"
                           className={`nav-item-link no-select ${activeLink === 'roadmap' ? 'active' : ''}`}
                           onClick={() => handleSetActiveLink('roadmap')}>ROADMAP</a>
                    </li>
                    <li className='nav-item'>
                        <a href="#tokenomics"
                           className={`nav-item-link no-select ${activeLink === 'tokenomics' ? 'active' : ''}`}
                           onClick={() => handleSetActiveLink('tokenomics')}>TOKENOMICS</a>
                    </li>
                    <li className='nav-item'>
                        <a href="#airdrop"
                           className={`nav-item-link no-select airdrop-link ${activeLink === 'airdrop' ? 'active' : ''}`}
                           onClick={() => handleSetActiveLink('airdrop')}><p className="airdrop">AIRDROP</p></a>
                    </li>
                    <li className='nav-item'>
                        <a href="#coreteam"
                           className={`nav-item-link no-select ${activeLink === 'coreteam' ? 'active' : ''}`}
                           onClick={() => handleSetActiveLink('coreteam')}>TEAM</a>
                    </li>
                    <li className='nav-item'>
                        <a href="#whitepaper"
                           className={`nav-item-link no-select ${activeLink === 'whitepaper' ? 'active' : ''}`}
                           onClick={handleWhitePaperClick}>WHITEPAPER</a>
                    </li>
                </ul>
                <div className="social-icons">
                    <div className='icons-wrapper'>
                        <div className="icon-container">
                            <a className='no-select' href="https://twitter.com/SamuraiRed2088" target="_blank"
                               rel="noopener noreferrer">
                                <FaXTwitter style={{width: '100%', height: '100%'}}/>
                            </a>
                        </div>
                        <div className="icon-container">
                            <a className='no-select' href="https://discord.gg/samurairedemption" target="_blank"
                               rel="noopener noreferrer">
                                <FaDiscord style={{width: '100%', height: '100%'}}/>
                            </a>
                        </div>
                        <div className="icon-container">
                            <a className='no-select' href="https://www.youtube.com/@SamuraiRedemption" target="_blank"
                               rel="noopener noreferrer">
                                <FaYoutube style={{width: '100%', height: '100%'}}/>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
