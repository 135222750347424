import React from 'react';
import './Card.css';

function Card({imageUrl, nftType, className}) {

    return (
        <div className={`card ${className}`}>
            <img src={imageUrl} alt="NFT"/>
            <div className="card-info">
                <p className="nft-type">{nftType}</p>
            </div>
        </div>
    );
}

export default Card;
