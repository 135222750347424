import "./Airdrop.css";
import React, {useEffect, useState} from "react";
import useVisibleOnScreen from "../../hooks/useVisibleOnScreen";
import {Dialog} from "../popup/dialog";
import {HeaderInfoIcon} from "../icons/HeaderInfoIcon";
import useIsIphone from "../../hooks/useIsIphone";
import useIsBrave from "../../hooks/useIsBrave";
import braveShieldScreenshot from "../../assets/airdrop/brave-shield.png";


const Airdrop = React.forwardRef(({scrollFinished, setActiveLink}, ref) => {

    const isVisible = useVisibleOnScreen(ref);
    const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);
    const [isAirdropDialogOpen, setAirdropDialogOpen] = useState(false);
    const [showBravePopup, setShowBravePopup] = useState(false);
    const isIphone = useIsIphone();
    const isBrave = useIsBrave();
    const iphoneStyle = isIphone ? {marginTop: '4vh'} : {};

    const openInfoDialog = () => setInfoDialogOpen(true);
    const closeInfoDialog = () => setInfoDialogOpen(false);
    const openAirdropDialog = () => setAirdropDialogOpen(true);
    const closeAirdropDialog = () => setAirdropDialogOpen(false);
    const closeBravePopup = () => setShowBravePopup(false);

    const infoDialogText = (
        <p>
            The Samurai Redemption Game introduces its exclusive token, <span className='highlight'>MON</span> with a
            capped supply of 1
            billion units,
            fostering a rare and valuable ecosystem. Meticulously planned token distribution aims to ensure stability in
            the token price, contributing to a sustainable and thriving in-game economy.
        </p>
    );

    const airdropDialogText = (
        <p>
            Oops! The Airdrop you're trying to claim hasn't begun. Don't worry, you're still on the list!
            Stay tuned for our announcement, and thanks for your enthusiasm!
        </p>
    );

    const braveWarningText = (
        <div>
            <img src={braveShieldScreenshot} alt="Disabling Brave Shields" style={{width: '80%'}} />
            <p>
                To participate in our airdrop and ensure full functionality, please disable Brave Shields for our site.
                Brave Shields may block essential elements required for the airdrop process, potentially restricting
                your
                ability to complete gleam tasks.
            </p>
        </div>
    );

    useEffect(() => {
        if (isVisible) {
            const hasSeenBravePopup = localStorage.getItem('hasSeenBravePopup');
            if (isBrave && !hasSeenBravePopup) {
                setShowBravePopup(true);
                localStorage.setItem('hasSeenBravePopup', 'true');
            }
        }
    }, [isVisible, isBrave]);

    useEffect(() => {
        if (isVisible) {
            const script = document.createElement('script');
            script.src = "https://widget.gleamjs.io/e.js";
            script.async = true;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            }
        }
    }, [isVisible]);

    return (
        <div ref={ref} className={`airdrop-container ${isVisible ? 'animate' : ''}`}>
            <div className="headline">
                <h1 className="airdrop-title">
                    AIR<span className="highlight">DROP</span>
                    <span className='airdrop-info-icon' onClick={openInfoDialog}>
                    <HeaderInfoIcon width='25' height='25'/>
                </span>
                </h1>
            </div>

            {showBravePopup && (
                <Dialog
                    type="Brave Warning"
                    title="Attention Brave Users"
                    text={braveWarningText}
                    onClose={closeBravePopup}
                />
            )}

            {isInfoDialogOpen && (
                <Dialog
                    type="info"
                    title="AIRDROP"
                    text={infoDialogText}
                    onClose={closeInfoDialog}
                />
            )}
            {isAirdropDialogOpen && (
                <Dialog
                    type="info"
                    title="AIRDROP"
                    text={airdropDialogText}
                    onClose={closeAirdropDialog}
                />
            )}
            <div className="airdrop-content">

                <div className="airdrop-text">
                    <div className="text-background-container">
                        <p className="text-overlay">
                            We are thrilled to announce an exclusive airdrop for our early <br></br>
                            supporters. Engage in designated tasks to earn <span
                            className="highlight">MON</span> Tokens,<br></br>
                            a token of our gratitude for your unwavering support.<br></br>
                            <br></br>
                            Act promptly, as there are defined limits and restrictions for<br></br>
                            the airdrop. As a conclusion, you will claim your tokens<br></br>
                            conveniently on our official website at the end of the airdrop.<br></br>
                            To stay informed about updates, we encourage you to<br></br>
                            follow us on our social media channels.<br></br>
                            <br></br>
                            This represents a unique opportunity for rewards, and we<br></br>
                            invite you to partake in this exciting journey with us at<br></br>
                            Samurai Redemption. Your early involvement is genuinely<br></br>
                            appreciated, and we look forward to sharing these tokens of<br></br>
                            appreciation with you.
                        </p>
                    </div>
                </div>
                <div className="airdrop-gleam">
                    <a className="e-widget no-button" href={process.env.REACT_APP_GLEAM_URL}
                       rel="nofollow"></a>
                </div>
            </div>

            <button onClick={openAirdropDialog} className="no-select claim-reward-button" style={iphoneStyle}>CLAIM
            </button>
        </div>
    );
});

export default Airdrop;
