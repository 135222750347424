import { useEffect, useState } from 'react';

const useVisibleOnScreen = (ref, threshold = 0.5) => {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const currentRef = ref.current; // Store current ref value
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            { threshold }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref, threshold]); // ref is stable, so this is okay

    return isIntersecting;
};

export default useVisibleOnScreen;
