import React from 'react';
import blurredBackgroundImage from '../../assets/dashboard/blurry-background-image.png'
import './Dashboard.css'
import useVisibleOnScreen from "../../hooks/useVisibleOnScreen";
import useIsIphone from "../../hooks/useIsIphone";

const Dashboard = React.forwardRef(({setActiveLink}, ref) => {
    const isVisible = useVisibleOnScreen(ref);

    const isIphone = useIsIphone();
    const isBrave = window.navigator.brave;
    const iphoneStyle = isIphone && isBrave === undefined ? {marginBottom: '5rem'} : {};


    const handleButtonClick = () => {
        window.open('https://docs.samuraired.io/', '_blank');
    };
    return (
        <div ref={ref} className={`dashboard ${isVisible ? 'animate' : ''}`}>
            <div className="dashboard-container" style={iphoneStyle}>
                <div className="blurred-background-box">
                    <img src={blurredBackgroundImage} alt="Blurred Background" className="blurred-bg-image"/>
                    <div className="text-content">
                        <h1>Samurai <span className="highlight">Redemption</span></h1>
                        <p>
                            Samurai Redemption: An engaging idle-strategy, play-to-earn (P2E) <br
                            className='responsive-break'></br>
                            game based on NFTs, developed on the Solana blockchain. Game, <br
                            className='responsive-break'></br>
                            where entertainment meets tangible rewards.
                        </p>
                        <button className="no-select see-more-btn" onClick={handleButtonClick}>
                            See More
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Dashboard;
