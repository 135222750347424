import React from 'react';

export const ErrorIcon = ({width = "50", height = "50", fill = "none"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44 39" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect x="13" y="12" width="18" height="19" fill="white"/>
        <path
            d="M22.0004 0.339844C23.9104 0.339844 25.6904 1.27384 26.7804 2.83384L26.9904 3.15384L43.2184 30.2498C43.7115 31.104 43.9799 32.0693 43.9982 33.0555C44.0166 34.0416 43.7844 35.0163 43.3233 35.8882C42.8623 36.7601 42.1875 37.5008 41.3622 38.0408C40.5368 38.5808 39.588 38.9025 38.6044 38.9758L38.2144 38.9918H5.76437C4.77978 38.9808 3.81405 38.7204 2.95732 38.2351C2.10058 37.7497 1.38079 37.0552 0.86511 36.2164C0.349434 35.3776 0.0546931 34.4218 0.00839889 33.4382C-0.0378953 32.4547 0.165766 31.4754 0.600374 30.5918L0.798375 30.2218L17.0184 3.14584C17.5377 2.28947 18.269 1.58138 19.1416 1.08988C20.0143 0.598383 20.9988 0.340064 22.0004 0.339844ZM22.0204 26.9998L21.7664 27.0138C21.2803 27.0717 20.8323 27.3058 20.5072 27.6718C20.1821 28.0378 20.0026 28.5103 20.0026 28.9998C20.0026 29.4894 20.1821 29.9619 20.5072 30.3279C20.8323 30.6939 21.2803 30.928 21.7664 30.9858L22.0004 30.9998L22.2544 30.9858C22.7405 30.928 23.1885 30.6939 23.5135 30.3279C23.8386 29.9619 24.0181 29.4894 24.0181 28.9998C24.0181 28.5103 23.8386 28.0378 23.5135 27.6718C23.1885 27.3058 22.7405 27.0717 22.2544 27.0138L22.0204 26.9998ZM22.0004 12.9998C21.5105 12.9999 21.0377 13.1798 20.6716 13.5053C20.3056 13.8308 20.0717 14.2793 20.0144 14.7658L20.0004 14.9998V22.9998L20.0144 23.2338C20.0722 23.7199 20.3063 24.168 20.6723 24.493C21.0383 24.8181 21.5108 24.9976 22.0004 24.9976C22.4899 24.9976 22.9624 24.8181 23.3284 24.493C23.6945 24.168 23.9286 23.7199 23.9864 23.2338L24.0004 22.9998V14.9998L23.9864 14.7658C23.9291 14.2793 23.6952 13.8308 23.3291 13.5053C22.963 13.1798 22.4902 12.9999 22.0004 12.9998Z"
            fill="#F13047"/>
    </svg>

);


