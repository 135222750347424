import './App.css';
import Header from "./components/header/Header";
import Nft from "./components/nft-collection/nft/Nft";
import Dashboard from "./components/dashboard/Dashboard";
import YoutubePlayer from "./components/youtube-player/YoutubePlayer";
import {useEffect, useRef, useState} from "react";
import RoadMap from "./components/roadmap/RoadMap";
import CoreTeam from "./components/team/core-team/CoreTeam";
import Tokenomics from "./components/tokenomics/Tokenomics.js";
import Airdrop from "./components/airdrop/Airdrop";

function App() {

    // Scrolling params
    const [activeSection, setActiveSection] = useState('dashboard');
    let lastScrollTime = Date.now();
    const coolDownPeriod = 1350;
    const [scrollFinished, setScrollFinished] = useState(false);

    // Burger menu
    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

    // Set Active link on header
    const [activeLink, setActiveLink] = useState('dashboard');
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

    // References
    const dashboardRef = useRef(null);
    const youtubePlayerRef = useRef(null);
    const nftRef = useRef(null);
    const roadMapRef = useRef(null);
    const tokenomicsRef = useRef(null);
    const coreteamRef = useRef(null);
    const airdropRef = useRef(null);
    const snapContainerRef = useRef(null);

    // Sections for references
    const sections = [dashboardRef, youtubePlayerRef, nftRef, roadMapRef, tokenomicsRef, airdropRef, coreteamRef];
    const activeLinkSections = ['dashboard', 'trailer', 'nft', 'roadmap', 'tokenomics', 'airdrop', 'coreteam'];

    // touch trackers for mobile
    const touchStartX = useRef(0);
    const touchStartY = useRef(0);
    const touchMoveY = useRef(0);
    const swipeHandled = useRef(false);

    const scrollToSection = (sectionIndex) => {
        const sectionRef = sections[sectionIndex];

        if (sectionRef && sectionRef.current) {
            // Set active link for menu
            let section = activeLinkSections[sectionIndex];
            setActiveLink(section);
            // Set active line height and section for scroll bar
            setActiveSection(section);
            setCurrentSectionIndex(sectionIndex);
            sectionRef.current.scrollIntoView();
        }
    };

    const handleScroll = (direction) => {
        let nextSectionIndex = currentSectionIndex;
        if (direction === 'down') {
            nextSectionIndex = Math.min(sections.length - 1, currentSectionIndex + 1);
        } else {
            nextSectionIndex = Math.max(0, currentSectionIndex - 1);
        }
        scrollToSection(nextSectionIndex);
    };

    useEffect(() => {
        const snapContainer = snapContainerRef.current;

        const onScroll = (e) => {
            const now = Date.now();
            e.preventDefault();

            if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
                return;
            }

            if (now - lastScrollTime > coolDownPeriod) {
                lastScrollTime = now;
                const direction = e.deltaY > 0 ? 'down' : 'up';
                handleScroll(direction);
            }
        };


        snapContainer.addEventListener('wheel', onScroll, {passive: false});
        snapContainer.addEventListener('', onScroll, {passive: false});

        const threshold = 20;
        const onTouchStart = (e) => {
            touchStartY.current = e.touches[0].clientY;
            touchStartX.current = e.touches[0].clientX;
        };

        const onTouchMove = (e) => {
            const moveX = e.touches[0].clientX;
            const moveY = e.touches[0].clientY;
            const diffX = Math.abs(moveX - touchStartX.current);
            const diffY = Math.abs(moveY - touchStartY.current);

            if (diffX > diffY) {
                return; // It's a horizontal move, do nothing
            }

            e.preventDefault();
            if (!swipeHandled.current && diffY > threshold) {
                const direction = moveY < touchStartY.current ? 'down' : 'up';
                handleScroll(direction);
                swipeHandled.current = true;
            }
        };

        const onTouchEnd = () => {
            swipeHandled.current = false;
        };

        snapContainer.addEventListener('touchstart', onTouchStart, {passive: false});
        snapContainer.addEventListener('touchmove', onTouchMove, {passive: false});
        snapContainer.addEventListener('touchend', onTouchEnd, {passive: false});

        return () => {
            snapContainer.removeEventListener('wheel', onScroll);
            snapContainer.removeEventListener('touchstart', onTouchStart);
            snapContainer.removeEventListener('touchmove', onTouchMove);
            snapContainer.removeEventListener('touchend', onTouchEnd);
        };
    }, [currentSectionIndex]);


    const debounce = (func, delay) => {
        let inDebounce;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const onScroll = debounce((e) => {
        const clientWindowHeight = Math.floor(e.target.clientHeight);
        const scrolledFromTop = Math.floor(e.target.scrollTop);
        setScrollFinished(false);
        if (scrolledFromTop % clientWindowHeight === 0) {
            setScrollFinished(true);
        }
    }, 5);

    return (
        <div className="App">
            <div className={`scrollbar-container ${isBurgerMenuOpen ? 'hidden' : ''}`}>
                <div
                    className={`scrollbar-item ${activeSection === 'dashboard' ? 'active' : ''}`}
                    onClick={() => scrollToSection(0)}
                />

                <div
                    className={`scrollbar-item ${activeSection === 'trailer' ? 'active' : ''}`}
                    onClick={() => scrollToSection(1)}
                />

                <div
                    className={`scrollbar-item ${activeSection === 'nft' ? 'active' : ''}`}
                    onClick={() => scrollToSection(2)}
                />

                <div
                    className={`scrollbar-item ${activeSection === 'roadmap' ? 'active' : ''}`}
                    onClick={() => scrollToSection(3)}
                />

                <div
                    className={`scrollbar-item ${activeSection === 'tokenomics' ? 'active' : ''}`}
                    onClick={() => scrollToSection(4)}
                />

                <div
                    className={`scrollbar-item ${activeSection === 'airdrop' ? 'active' : ''}`}
                    onClick={() => scrollToSection(5)}
                />

                <div
                    className={`scrollbar-item ${activeSection === 'coreteam' ? 'active' : ''}`}
                    onClick={() => scrollToSection(6)}
                />

            </div>
            <Header
                activeLink={activeLink}
                setActiveLink={setActiveLink}
                dashboardRef={dashboardRef}
                youtubePlayerRef={youtubePlayerRef}
                nftRef={nftRef}
                roadMapRef={roadMapRef}
                tokenomicsRef={tokenomicsRef}
                airdropRef={airdropRef}
                coreteamRef={coreteamRef}
                setCurrentSectionIndex={setCurrentSectionIndex}
                setActiveSection={setActiveSection}
                isBurgerMenuOpen={isBurgerMenuOpen}
                setIsBurgerMenuOpen={setIsBurgerMenuOpen}
            />
            <div onScroll={onScroll} className="snap-container" ref={snapContainerRef}>
                <Dashboard ref={dashboardRef} setActiveLink={setActiveLink}/>
                <YoutubePlayer ref={youtubePlayerRef} setActiveLink={setActiveLink}/>
                <Nft scrollFinished={scrollFinished} ref={nftRef} setActiveLink={setActiveLink}/>
                <RoadMap scrollFinished={scrollFinished} ref={roadMapRef} setActiveLink={setActiveLink}/>
                <Tokenomics ref={tokenomicsRef} setActiveLink={setActiveLink}/>
                <Airdrop scrollFinished={scrollFinished} ref={airdropRef} setActiveLink={setActiveLink}/>
                <CoreTeam scrollFinished={scrollFinished} ref={coreteamRef} setActiveLink={setActiveLink}/>
            </div>
        </div>
    );
}

export default App;
