import React, {useRef} from 'react';
import Card from './Card'; // Import your Card component
import nft1 from '../../../assets/nft/ronin-nft-2.webp';
import nft2 from '../../../assets/nft/hacker-nft-2.webp';
import nft3 from '../../../assets/nft/hacker-nft-1.webp';
import createNftImage from '../../../assets/nft/create-nft-image.png';
import useVisibleOnScreen from "../../../hooks/useVisibleOnScreen";

function CardCollection() {
    const ref = useRef(null);
    const isVisible = useVisibleOnScreen(ref);

    // Sample data - replace with real data
    const cardsData = [
        {imageUrl: nft1},
        {imageUrl: nft2},
        {imageUrl: nft3}
    ];

    return (
        <div ref={ref} className={`card-container ${isVisible ? 'animate' : ''}`}>
            <Card className='middle'
                  imageUrl={cardsData[0].imageUrl}
                  nftType='Ronin'
            />
            <Card
                className="left-tilt"
                imageUrl={cardsData[1].imageUrl}
                nftType='Hacker'
            />
            <Card
                className="right-tilt"
                imageUrl={cardsData[2].imageUrl}
                nftType='Hacker'
            />
            <img src={createNftImage} className='create-nft-img'/>
        </div>
    );
}

export default CardCollection;
