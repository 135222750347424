import React, {useEffect, useRef, useState} from 'react';
import CardCollection from '../card/CardCollection'; // Import your CardCollection component
import './Nft.css';
import useVisibleOnScreen from "../../../hooks/useVisibleOnScreen";
import NftBox from "../nft-box/NftBox";
import hackerNft1 from '../../../assets/nft/hacker-nft-1.webp';
import hackerNft2 from '../../../assets/nft/hacker-nft-2.webp';
import roninNft from '../../../assets/nft/ronin-nft-2.webp';
import useIsIphone from "../../../hooks/useIsIphone";
import {Dialog} from "../../popup/dialog";

const Nft = React.forwardRef(({setActiveLink, scrollFinished}, ref) => {
    const isVisible = useVisibleOnScreen(ref);
    const horizontalScrollRef = useRef(null);
    const firstScrollItemRef = useRef(null);
    const initialScrollItemRef = useRef(null);
    const [centeredIndex, setCenteredIndex] = useState(null);
    const [isPresaleDialogOpen, setPresaleDialogOpen] = useState(false);

    const nftImages = [hackerNft1, roninNft, hackerNft2];
    const isIphone = useIsIphone();
    const iphoneStyle = isIphone ? {marginBottom: '2.7rem'} : {};

    const openPresaleDialog = () => setPresaleDialogOpen(true);
    const closePresaleDialog = () => setPresaleDialogOpen(false);
    const presaleDialogText = (
        <p>
            Presales Coming Soon! Stay tuned on our Discord server for the upcoming announcement. Don't miss out !
        </p>
    );

    const handleWhitePaperClick = () => {
        window.open('https://docs.samuraired.io/', '_blank');
    };

    const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }


    const updateCenteredIndex = () => {
        const container = horizontalScrollRef.current;
        const children = container.children;
        let centeredIdx = null;

        // Get the center point of the container
        const containerCenter = container.scrollLeft + container.offsetWidth / 2;

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.tagName === 'IMG') {
                const childStart = child.offsetLeft;
                const childEnd = childStart + child.offsetWidth;

                // Check if the center of the container is within the bounds of the child
                if (containerCenter >= childStart && containerCenter <= childEnd) {
                    centeredIdx = i - 1;
                    break;
                }
            }
        }

        setCenteredIndex(centeredIdx);
    };

    useEffect(() => {
        if (horizontalScrollRef.current && initialScrollItemRef.current) {
            requestAnimationFrame(() => {
                const scrollContainer = horizontalScrollRef.current;
                const itemToCenter = initialScrollItemRef.current;

                // Get the distance from the item's left edge to the container's left edge
                const itemLeftOffset = itemToCenter.offsetLeft;

                const halfItemWidth = itemToCenter.offsetWidth / 2;
                scrollContainer.scrollLeft = itemLeftOffset + halfItemWidth - (scrollContainer.offsetWidth / 2);
            });
        }
        const container = horizontalScrollRef.current;
        container.addEventListener('scroll', debounce(updateCenteredIndex, 100));

        return () => {
            container.removeEventListener('scroll', debounce(updateCenteredIndex, 100));
        };
    }, []);


    return (
        <div ref={ref} className={`nft-container ${isVisible ? 'animate' : 'animate-reverse'}`}>
            {isPresaleDialogOpen && (
                <Dialog
                    type="info"
                    title="Pre-Sales"
                    text={presaleDialogText}
                    onClose={closePresaleDialog}
                />
            )}
            <div className={`text-section ${isVisible ? '' : ''}`}>
                <h1 className="text-section-header">Playable <span className="highlight">NFT</span> Collection <br></br>
                    With Tangible Rewards
                </h1>
                <p className="text-section-paragraph">
                    The collection consists of two types of NFTs: <br></br>
                    Hackers & Ronins, each with their own unique<br></br>
                    abilities and benefits
                </p>
                <div ref={horizontalScrollRef}
                     style={scrollFinished ? {overflowX: 'scroll'} : {overflowX: 'hidden'}}
                     className='embedded-nft-boxes-container'>
                    <div className='spacer'></div>
                    {nftImages.map((src, index) => (
                        <img
                            key={src}
                            ref={index === 0 ? firstScrollItemRef : index === 1 ? initialScrollItemRef : null}
                            className={`embedded-nft-card-image ${centeredIndex === index ? 'centered-card' : ''}`}
                            src={src}
                            alt='nft'
                        />
                    ))}
                    <div className='spacer'></div>
                </div>
                <div className="buttons-container" style={iphoneStyle}>
                    <button className="no-select whitepaper-btn" onClick={handleWhitePaperClick}>Whitepaper</button>
                    <button className="no-select presales-btn" onClick={openPresaleDialog}>Pre-Sales</button>
                </div>
                <div className="nft-boxes-container">
                    <NftBox title="Size" text="TBA"/>
                    <NftBox title="NFT" text="2 types"/>
                    <NftBox title="Utility" text="P2E"/>
                </div>

            </div>
            <div className={`card-collection-section ${isVisible ? 'animate' : ''}`}>
                <CardCollection/>
            </div>
        </div>
    );
});

export default Nft;
