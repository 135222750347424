import React from 'react';

export const InfoIcon = ({width = "50", height = "50", fill = "none"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 42 42" fill="none">
        <rect x="12" y="10" width="18" height="24" fill="white"/>
        <path
            d="M20.8333 0C32.3396 0 41.6667 9.32709 41.6667 20.8333C41.671 26.3031 39.5241 31.5551 35.6894 35.4555C31.8547 39.3559 26.6399 41.5918 21.1709 41.6804C15.7019 41.769 10.4174 39.7033 6.45833 35.9292C2.49929 32.155 0.183309 26.9753 0.0104169 21.5083L0 20.8333L0.00833323 20.25C0.316667 9.01458 9.52083 0 20.8333 0ZM20.8333 18.75H18.75L18.5063 18.7646C17.9999 18.8248 17.5332 19.0687 17.1946 19.4499C16.856 19.8312 16.669 20.3234 16.669 20.8333C16.669 21.3433 16.856 21.8355 17.1946 22.2167C17.5332 22.598 17.9999 22.8419 18.5063 22.9021L18.75 22.9167V29.1667L18.7646 29.4104C18.8194 29.8755 19.0293 30.3085 19.3604 30.6396C19.6916 30.9707 20.1246 31.1806 20.5896 31.2354L20.8333 31.25H22.9167L23.1604 31.2354C23.6255 31.1806 24.0585 30.9707 24.3896 30.6396C24.7207 30.3085 24.9306 29.8755 24.9854 29.4104L25 29.1667L24.9854 28.9229C24.9356 28.4979 24.7561 28.0985 24.4713 27.779C24.1866 27.4596 23.8103 27.2356 23.3938 27.1375L23.1604 27.0958L22.9167 27.0833V20.8333L22.9021 20.5896C22.8472 20.1246 22.6373 19.6916 22.3062 19.3604C21.9751 19.0293 21.5421 18.8194 21.0771 18.7646L20.8333 18.75ZM20.8542 12.5L20.5896 12.5146C20.0832 12.5748 19.6166 12.8187 19.2779 13.1999C18.9393 13.5812 18.7523 14.0734 18.7523 14.5833C18.7523 15.0933 18.9393 15.5855 19.2779 15.9667C19.6166 16.348 20.0832 16.5919 20.5896 16.6521L20.8333 16.6667L21.0979 16.6521C21.6043 16.5919 22.071 16.348 22.4096 15.9667C22.7482 15.5855 22.9352 15.0933 22.9352 14.5833C22.9352 14.0734 22.7482 13.5812 22.4096 13.1999C22.071 12.8187 21.6043 12.5748 21.0979 12.5146L20.8542 12.5Z"
            fill="#007AFF"/>
    </svg>

);


