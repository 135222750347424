import { useEffect, useState } from 'react';

function useIsBrave() {
    const [isBrave, setIsBrave] = useState(false);

    useEffect(() => {
        async function checkBrave() {
            const isNavigatorBrave = navigator.brave && await navigator.brave.isBrave();
            const hasBraveUserAgent = window.navigator.userAgent.includes('Brave');
            setIsBrave(isNavigatorBrave || hasBraveUserAgent);
        }

        checkBrave();
    }, []);

    return isBrave;
}

export default useIsBrave;
