import React, {useEffect, useRef, useState} from 'react';
import jeremiah from '../../../assets/nft/hacker-nft-2.webp';
import frenkie from '../../../assets/team/frenkie.webp';
import spud from '../../../assets/team/spud.webp';
import bignemanja from '../../../assets/team/bignemanja.webp';
import jimmy from '../../../assets/team/jimmy.webp';
import suzaku from '../../../assets/team/suzaku.webp';
import './CoreTeam.css';
import CoreTeamCard from "./CoreTeamCard";
import Footer from "../../footer/Footer";
import {HeaderInfoIcon} from "../../icons/HeaderInfoIcon";
import {Dialog} from "../../popup/dialog";
import useVisibleOnScreen from "../../../hooks/useVisibleOnScreen";

const CoreTeam = React.forwardRef(({scrollFinished, setActiveLink}, ref) => {
    const isVisible = useVisibleOnScreen(ref);
    const [centeredIndex, setCenteredIndex] = useState(null);
    const horizontalScrollRef = useRef(null);

    const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);


    const openInfoDialog = () => setInfoDialogOpen(true);
    const closeInfoDialog = () => setInfoDialogOpen(false);
    const infoDialogText = (
        <p>Our team is a
            powerhouse of seasoned professionals, experts in Web3 & Web2, with deep roots in game development,
            mobile apps, backend
            infrastructure, UI/UX, marketing and crypto. With a wealth of skills and collective expertise, we're
            set to revolutionize gaming with an
            unparalleled decentralized entertainment experience
        </p>
    );

    const owners = [
        {
            image: frenkie,
            className: 'flex-left',
            name: 'Founder',
            tag: '@MrFrenkie888',
            bio: 'Mr. Frenkie is a highly experienced Mobile Development lead known for his effective team management and successful guidance of indie game projects. Over the past three years, he has been deeply engaged in Web3 activities, and most recently, he played a pivotal role in co-founding the innovative Travelers DAO.'
        },
        {
            image: jeremiah,
            className: 'flex-middle',
            name: 'Co-Founder',
            tag: '@Jeremiah__77',
            bio: 'Crypto enthusiast and strategic marketer, Jeremiah founded Travelers DAO for impactful crypto investments. An OG Solana NFT collector, he\'s committed to building a vibrant community and creating lasting value in crypto with innovative strategies. Previously, he led a marketing team in a high-impact Web2 project, achieving notable success.'
        },
        {
            image: spud,
            className: 'flex-right',
            name: 'Artist',
            tag: '@theartistspud',
            bio: 'Spud, the creative director of Cets, is known for his imaginative and unconventional drawings, which give a unique flair to his creations. The significant projects he has worked on, such as Seinseis, Gruuvies, and Rebels Revenue, each highlight his distinct style and inventive approach in the world of contemporary art.'
        },
        {
            image: jimmy,
            className: 'flex-left',
            name: 'Cto',
            tag: '@jimmy256_sol',
            bio: 'A Fullstack developer with 8 years of experience, half of which is in blockchain technology. Expert in marrying front-end and back-end development with blockchain solutions, showcasing a deep understanding of both traditional web technologies and the latest in decentralized applications.'
        },
        {
            image: bignemanja,
            className: 'flex-middle',
            name: 'Dev',
            tag: '@BigNemanja1',
            bio: 'Skilled senior full-stack developer with deep experience in handling high-traffic applications, particularly within the banking sector. Currently excelling as a Site Reliability Engineer, he brings critical best practices to the team, ensuring applications remain robust and reliably operational.'
        },
        {
            image: suzaku,
            className: 'flex-right',
            name: 'Designer',
            tag: '@Suzaku_Sol',
            bio: 'An experienced designer with a seven-year track record, adept in web3 projects across exchange platforms and P2E games. Renowned for creating futuristic, user-centric designs that elevate application usability and deeply engage users in a rapidly evolving digital environment.'
        }
    ];

    useEffect(() => {
        const container = horizontalScrollRef.current;
        container.addEventListener('scroll', debounce(updateCenteredIndex, 100));

        return () => {
            container.removeEventListener('scroll', debounce(updateCenteredIndex, 100));
        };
    }, []);
    const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    const updateCenteredIndex = () => {
        const container = horizontalScrollRef.current;
        const children = container.children;
        let centeredIdx = null;

        // Get the center point of the container
        const containerCenter = container.scrollLeft + container.offsetWidth / 2;

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.className.includes('team-card-wrapper')) {
                const childStart = child.offsetLeft;
                const childEnd = childStart + child.offsetWidth;

                // Check if the center of the container is within the bounds of the child
                if (containerCenter >= childStart && containerCenter <= childEnd) {
                    centeredIdx = i - 1;
                    break;
                }
            }
        }

        setCenteredIndex(centeredIdx);
    };

    return (
        <div ref={ref} className={`coreteam-container ${isVisible ? 'animate' : 'animate-reverse'}`}>

            <div className="coreteam-header">
                <h1 className="coreteam-title">
                    CORE <span className="highlight">TEAM</span>
                    <span className='coreteam-info-icon' onClick={openInfoDialog}>
                    <HeaderInfoIcon width='25' height='25'/>
                </span>
                </h1>
                <p>Embark on an extraordinary adventure with our Web3 NFT game project, which blending innovation and
                    expertise. Our team is a <br></br>
                    powerhouse of seasoned professionals, experts in Web3 & Web2, with deep roots in game development,
                    mobile apps, backend<br></br>
                    infrastructure, UI/UX, marketing and crypto. With a wealth of skills and collective expertise, we're
                    set to revolutionize gaming with an<br></br>
                    unparalleled decentralized entertainment experience</p>
            </div>


            {isInfoDialogOpen && (
                <Dialog
                    type="info"
                    title="CORE TEAM"
                    text={infoDialogText}
                    onClose={closeInfoDialog}
                />
            )}
            <div ref={horizontalScrollRef} className="coreteam-card-container no-select"
                 style={scrollFinished ? {overflowX: 'scroll'} : {}}>
                <div className='spacer'></div>
                {owners.map((owner, index) => (
                    <div
                        key={index}
                        className={`team-card-wrapper ${owner.className} ${centeredIndex === index ? 'centered-card' : ''}`}>
                        <CoreTeamCard
                            imageUrl={owner.image}
                            name={owner.name}
                            tag={owner.tag}
                            bio={owner.bio}
                        />
                    </div>
                ))}
                <div className='spacer'></div>
            </div>

            <div className='mobile-text'>
                <p>
                    Our team is a powerhouse of seasoned professionals, experts in Web3 & Web2, with deep roots in game
                    development, mobile apps, backend infrastructure, UI/UX, marketing and crypto. With a wealth of
                    skills and collective expertise, we're set to revolutionize gaming with an unparalleled
                    decentralized entertainment experience
                </p>
            </div>
            <Footer/>
        </div>
    );
});

export default CoreTeam;